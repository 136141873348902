import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import PortableText from "../../components/Blog/portableText";
// import ButtonGhost from "../../components/Button/ButtonGhost";
const AboutUsSection1 = ({
  // headingLevel,
  // className,
  sectionTitle,
  sectionDesc,
  // headingtitle1,
  // headingdescription1,  
  // headingtitle2,
  // headingdescription2,

}) => {
  return (
    <section className="bg-tertiary-50 relative md:pt-0">
      <div className="container hidden md:block">
        <div className="grid gap-y-12 gap-x-[20px] md:grid-cols-2">
          <div className="md:py-24 lg:py-36 lg:pr-20">
            <h1>{sectionTitle}</h1>
            <p>{sectionDesc}</p>
            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
          <div className="relative md:h-full md:py-24  ">
            <div className="">
              <StaticImage
                src="../../images/4.0 About/1.0 About.jpg"
                loading="eager"
                placeholder="none"
                layout="constrained"
                className="rounded-[8px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="grid gap-y-12 md:grid-cols-2 ">
          <div className="md:py-24 lg:py-36 text-center ">
            <h1>{sectionTitle}</h1>
            <p>{sectionDesc}</p>
            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection1;
