import React from "react";
// import ButtonSolid from "../../components/Button/ButtonSolid";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsSection2 = ({
  // headingLevel,
  // className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className=" ">
        <div className="relative h-full">
          {/* START SECTION WRAPPER */}
          <div className="relative flex flex-col items-center justify-center justify-items-center">
            {/* START UNDERLAY CONTAINER */}
            <div className="aboslute top-0 left-0  w-full">
              <StaticImage
                src="../../images/4.0 About/mission-desktop.jpg"
                className=""
              />
            </div>
            {/* END OF UNDERLAY CONTAINER */}
            {/* START CONTENT */}
            <div className="container absolute  mx-auto mt-0  md:pt-0 h-full flex items-center justify-center justify-items-center ">
              <div className="grid gap-y-8">
                <div className=" mx-auto text-center lg:max-w-[65%]">
                  <h2 className="text-white">{sectionTitle}</h2>
                  <p className="text-white">{sectionDesc}</p>
                </div>
              </div>
            </div>
            {/* END OF CONTENT */}
          </div>
          {/* END OF SECTION WRAPPER */}
        </div>

        {/* <div className="absolute bottom-0 left-0 hidden w-full overflow-hidden  pt-18 md:block  "></div>
        <div className="absolute bottom-0 left-0 block h-full w-full md:hidden   ">
          <StaticImage
            src="../../images/4.0 About/mission-mobile.jpg"
            objectFit="cover"
          />
        </div> */}
        {/* <div className="container relative mx-auto mt-0 pt-18 md:pt-0">
          <div className="grid gap-y-8">
            <div className=" mx-auto text-center lg:max-w-[65%]">
              <h2 className="text-white">{sectionTitle}</h2>
              <p className="text-white">{sectionDesc}</p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default AboutUsSection2;
